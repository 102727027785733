.section {
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
}

.subsection {
    background-color: var(--primary-color);
    color: white;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 900px) {
    .subsection {
        text-align: center;
        padding: 0px 10px;
    }
}