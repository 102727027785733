.services {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
}

.service_block {
    /* box-shadow: inset 0 0 0 0 var(--primary-color);
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out; */
    width:var(--block-width);
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: var(--border-radius-md);
}

/* .service_block:hover {
    box-shadow: inset var(--block-width) 0 0 0 var(--primary-color);
    color: white;
} */

.service_icon {
    display: flex;
    justify-content: center;
    height: 80px;
}

h4 {
    margin: 0px;
    padding-top: 16px;
}

@media only screen and (max-width: 900px) {
    .services {
        flex-direction: column;
    }
}