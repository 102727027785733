:root {
    /* COLORS */
    /* Main Palette */
    --primary-color: #D3395E;
    /* --secondary-color: #eb4468;
    --primary-dark-color: #41255e;
    --primary-light-color: #aa14f0;
    --dark-color: #010208; */
    /* Extra.. */
    /* --error-color: #c12020;
    --success-color: green;
    --warning-color: orange;
    --blog-color: #6203c1; */
    /* Grayscale */
    --black-color: #000;
    --dark-gray-color: #1a1c25;
    --gray-color: #767676;
    --light-gray-color: #c8c8c8;
    --white-color: #fff;
  
    /* FONTS */
    --font-family: 'Inter';
    /* Font Sizes */
    /* ->Desktop */
    --font-size-desktop-xs: 14px;
    --font-size-desktop-sm: 18px;
    --font-size-desktop-md: 32px;
    --font-size-desktop-lg: 40px;
    --font-size-desktop-xl: 56px;
    /* ->Mobile */
    /* --font-size-mobile-xs: 12px;
    --font-size-mobile-sm: 14px;
    --font-size-mobile-md: 16px;
    --font-size-mobile-lg: 24px;
    --font-size-mobile-xl: 28px; */
  
    /* PAGE PADDINGS (left and right) */
    --page-padding-sm: 20px;
    --page-padding-md: 80px;
    --page-padding-lg: 135px;
  
    /* Spacing */
  
    /* Border Radius */
    --border-radius-sm: 4px;
    --border-radius-md: 14px;
    --border-radius-lg: 30px;

    --page-max-width: 900px;
    --block-width: 290px;
  }
  
  html,
  body {
    top: 0;
    padding: 0;
    margin: 0;
    font-family: var(--font-family);
    scroll-behavior: smooth;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }

  h1 {
    font-size: var(--font-size-desktop-xl);
    margin: 0px;
    padding: 10px 0px;
  }
  h3 {
    font-size: var(--font-size-desktop-md);
    margin: 0px;
    padding: 5px 0px;
    font-weight: 400;
  }
  
  a:hover path {
    fill: var(--primary-color);
  }

  path:hover, path:active, path:focus {
    fill: var(--primary-color);
  }

  * {
    box-sizing: border-box;
  }
  
  footer {
    background-color: black;
    color: var(--light-gray-color);
    display: flex;
    justify-content: center;
  }

  footer p {
    margin: 0px;
    padding: 10px;
  }


  .mobile_only {
    display: none;
  }
  @media only screen and (max-width: 900px) {
  .desktop_only {
    display: none;
  }
  .mobile_only {
    display: block;
  }
  }