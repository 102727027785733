.work_block {
    box-shadow: inset 0 0 0 0 var(--primary-color);
    transition: box-shadow .3s ease-in-out;
    padding:  20px;
    background-color: white;
    border-radius: var(--border-radius-md);
    width: var(--page-max-width);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* .work_block:hover {
    box-shadow: inset 60px 0 0 var(--primary-color);
} */

.work_icon {
    transition: filter .1s ease-in-out, margin-left .3s ease-in-out ;
    display: flex;
    justify-content: center;
    margin-right: 20px;
}

/* .work_block:hover .work_icon {
    filter: invert(100%) brightness(100);
    margin-left: -5px;
} */

.work_skills {
    margin: 0px;
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.work_skills span {
    color: unset;
}

@media only screen and (max-width: 900px) {
    .work_block {
        width: unset;
        align-items: center;
        text-align: center;
        flex-direction: column;
        margin: 0px 10px;
        gap: 10px;
    }
    .work_skills {
        width: 100%;
        margin: 0px 10px;
    }
}