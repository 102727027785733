.section_container {
    padding: 60px 0px;
    display: flex;
    justify-content: center;
}

.section_content {
    width: var(--page-max-width);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section_header {
    font-weight: bold;
}

.section_subheader {
    text-align: center;
}

@media only screen and (max-width: 900px) {
    .section_subheader {
        padding: 0px 10px;
    }
}