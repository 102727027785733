
.section {
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
}

.content {
    width: var(--page-max-width);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.icons {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.icon {
cursor: pointer;
}

span {
    color: var(--primary-color);
}

@media only screen and (max-width: 900px) {
    .content {
        flex-direction: column;
    }
    .greetings {
        text-align: center;
    }
}