/* Title, Type, Date */
.work_details {
    transition: margin-left .3s ease-in-out ;
    width: 305px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

/* .work_block:hover .work_details {
    margin-left: 5px;
} */

.work_title {
    padding: 0px;
}

.work_type {
    margin: 0px;
}

.work_date {
   color: var(--gray-color);
   margin: 0px;
}

/* Duration */
.work_date span {
    opacity: 0.8;
}