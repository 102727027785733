
.icons {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.icon {
cursor: pointer;
}

@media only screen and (max-width: 900px) {
    .icons {
        justify-content: center;
    }
}